<template>
  <div>
    <v-dialog v-model="dialog" max-width="370" persistent>
      <v-card>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline">
              Panel de Control
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-form ref="form" @submit.prevent="login">
              <v-card-text>
                <v-text-field v-model="username" label="Usuario">
                </v-text-field>
                <v-text-field
                  v-model="password"
                  label="Clave"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                >
                </v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn color="success" type="submit">
                  <v-icon left>mdi-login</v-icon>
                  Ingresar
                </v-btn>
                <v-btn
                  color="accent dark-2"
                  @click="
                    $router.push({
                      name: 'userManual',
                    })
                  "
                >
                  <v-icon left>mdi-file-document</v-icon>
                  Manual de Uso
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="text-center">
              <p>
                Sólo usuarios administradores de la plataforma<br />
                pueden acceder al panel de control.
              </p>
              <p>
                Para obtener su usuario y contraseña,<br />
                póngase en contacto con su distribuidor<br />
                o su administración de consorcio.
              </p>
              <p> - Powered by TappBell - </p>
              <p>
                ®TappBell es una marca registrada<br />
                <a target="_blank" href="https://tappbell.com"
                  >www.TappBell.com</a
                >
                -
                <a target="_blank" href="mailto:info@tappbell.com"
                  >info@tappbell.com</a
                >
              </p>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { auth } from "@/fb";

export default {
  data() {
    return {
      dialog: true,
      show: false,
      snackbar: {
        visible: false,
        timeout: 2000,
        text: "",
        color: "",
      },
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      auth.signInWithEmailAndPassword(this.username, this.password).then(
        () => {
          this.snackbar.text = "Bienvenido " + this.username;
          this.snackbar.color = "success";
          this.snackbar.visible = true;
          this.$router.push("system");
        },
        (error) => {
          console.error(error);
          this.snackbar.text = error.message;
          this.snackbar.color = "error";
          this.snackbar.visible = true;
        }
      );
    },
  },
};
</script>

<style></style>
