<template>
  <div class="nav-menu">
    <v-navigation-drawer
      width="200"
      permanent
      height="100vh"
      :color="whiteLabel.colors.primary"
      dark
      :mini-variant="mini"
      mini-variant-width="80"
    >
      <v-list nav>
        <v-list-item-group :color="whiteLabel.colors.fontPrimary" mandatory>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            router
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-footer v-if="license.whiteLabel" color="black" app>
        <div class="text-centered">- Powered by TappBell -</div>
      </v-footer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [
        { icon: "fa-gear", title: "Sistema", route: "/system" },
        { icon: "fa-cogs", title: "Configuración", route: "/configuration" },
        { icon: "fa-building", title: "Edificio", route: "/building" },
        { icon: "fa-database", title: "Plantas", route: "/floors" },
        { icon: "fa-th", title: "Unidades", route: "/units" },
        { icon: "fa-user", title: "Residentes", route: "/residents" },
        { icon: "mdi-history", title: "Registros", route: "/logs" },
        { icon: "mdi-video-wireless", title: "Cámaras", route: "/cameras" },
        { icon: "mdi-toolbox", title: "Herramientas", route: "/tools" },
      ],
    };
  },

  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    ...mapGetters({
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  &::after {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid white;
    position: absolute;
    right: -25px;
  }
}
</style>
