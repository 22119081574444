import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Login.vue";
import SigninReset from "../components/SigninReset.vue";
import { auth } from "@/fb";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signin",
    alias: "/resetpwd",
    name: "signinreset",
    component: SigninReset,
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/system",
    name: "system",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/System.vue"),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/configuration",
    name: "configuration",
    component: () =>
      import(
        /* webpackChunkName: "configuation" */ "../views/Configuration.vue"
      ),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/building",
    name: "building",
    component: () =>
      import(/* webpackChunkName: "building" */ "../views/Building.vue"),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/floors",
    name: "floors",
    component: () =>
      import(/* webpackChunkName: "floors" */ "../views/Floors.vue"),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/units",
    name: "units",
    component: () =>
      import(/* webpackChunkName: "units" */ "../views/Units.vue"),
    meta: {
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/residents",
    name: "residents",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Residentes.vue"),
    meta: {
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import(/* webpackChunkName: "logs" */ "../views/Logs.vue"),
    meta: {
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/cameras",
    name: "cameras",
    component: () =>
      import(/* webpackChunkName: "cameras" */ "../views/Cameras.vue"),
    meta: {
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/tools",
    name: "tools",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Tools.vue"),
    meta: {
      needLogin: true,
    },
    props: true,
  },
  {
    path: "/manual",
    name: "userManual",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/UserManual.vue"),
    meta: {
      needLogin: false,
    },
    props: false,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

router.beforeEach(async (to, from, next) => {
  const needLogin = to.matched.some((record) => record.meta.needLogin);
  if (to.path == "/login" && (await getCurrentUser())) {
    next("home");
  }
  if (needLogin && !(await getCurrentUser())) {
    next("login");
  } else {
    next();
  }
});

export default router;
