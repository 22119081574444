import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/fb";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    license: null,
    whiteLabel: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
    license(state) {
      return state.license;
    },
    whiteLabel(state) {
      return state.whiteLabel;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_LICENSE(state, license) {
      state.license = license;
    },
    SET_WHITE_LABEL(state, whiteLabel) {
      state.whiteLabel = whiteLabel;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        let _building;
        user.getIdTokenResult().then((tokenResult) => {
          _building = tokenResult.claims.buildings
            ? tokenResult.claims.buildings[0]
            : tokenResult.claims.building;
          commit("SET_USER", {
            id: user.uid,
            displayName: user.displayName,
            email: user.email,
            building: _building,
          });
          db.doc("licenses/" + _building)
            .get()
            .then((license) => {
              db.doc("buildings/" + _building)
                .get()
                .then((building) => {
                  var _license = {};
                  _license.maxUnits = license.data().units;
                  _license.doors = license.data().doors;
                  _license.rfid = license.data().rfid;
                  _license.video = license.data().video;
                  _license.audio = license.data().audio;
                  _license.demo = license.data().demo;
                  _license.virtual = license.data().virtual ? true : false;
                  _license.gate = license.data().gate ? true : false;
                  _license.lite = license.data().lite ? true : false;
                  _license.activation = building.data().created.toDate();
                  _license.totalUnits = building.data().total_units;
                  _license.buildingAddress = building.data().address;
                  _license.buildingName = building.data().name;
                  _license.dealer = building.data().dealer
                    ? building.data().dealer
                    : "YhFgUmGMIR8kj2RlSNH1";
                  commit("SET_LICENSE", _license);

                  // Get White Label data.
                  db.doc("dealers/" + _license.dealer)
                    .get()
                    .then((dealer) => {
                      commit("SET_WHITE_LABEL", dealer.data().white_label);
                    })
                    .catch((error) => {
                      console.error("ERROR", error);
                    });
                });
            });
        });
      } else {
        commit("SET_USER", null);
        commit("SET_LICENSE", null);
        commit("SET_WHITE_LABEL", null);
      }
    },
  },
});
