<template>
  <v-app>
    <v-container class="main-container">
      <div v-if="this.user.loggedIn"><Header /></div>
      <v-row no-gutters>
        <v-col cols="auto" v-if="this.user.loggedIn">
          <NavMenu />
        </v-col>
        <v-col class="scroll-height">
          <v-main>
            <v-container>
              <router-view />
            </v-container>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Header from "./components/layout/Header";
import NavMenu from "./components/layout/NavMenu";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    Header,
    NavMenu,
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style lang="scss">
@import "@/sass/_mixins.scss";
@import "@/sass/_common.scss";

.scroll-height {
  height: 100vh;
  overflow-y: scroll;
}

.main-container {
  padding: 0;
  border: 0;
  border-radius: 2%;

  // Camera
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    width: 10px;
    background-color: dimgray;
    border-radius: 50%;
    display: none;
  }

  @include sm {
    padding: 0;
    border: 40px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 15px;
    }
  }

  @include md {
    padding: 0;
    border: 60px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 23px;
    }
  }

  @include lg-up {
    padding: 0;
    border: 80px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 33px;
    }
  }
}

.container {
  @include lg-up {
    max-width: 1185px;
  }
}
</style>
