import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import store from "./store";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1BzZDqHI2FEBZllvqoETXlKpwQwQ7xLs",
  authDomain: "tappbellcloud.firebaseapp.com",
  databaseURL: "https://tappbellcloud.firebaseio.com",
  projectId: "tappbellcloud",
  storageBucket: "tappbellcloud.appspot.com",
  messagingSenderId: "652256249750",
  appId: "1:652256249750:web:f46d3c081647f99d0c7fb7",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const ff = firebase.functions();

db.settings({ timestampsInSnapshots: true });

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch("fetchUser", user);
  } else {
    store.dispatch("fetchUser", null);
  }
});

export { db, auth, ff, firebase };
