import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueHtmlToPaper from "vue-html-to-paper";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import * as GmapVue from "gmap-vue";

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//HtmlToPaper
const options = {
  name: "_blank",
  specs: ["fullscreen=no", "titlebar=no", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
  ],
};

Vue.config.productionTip = false;
Vue.use(VueFilterDateFormat);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyC8LaQj1ZE50aJO9r5pv01J1rUkAhSKsTA",
    region: "AR",
    language: "es",
    libraries: "places, drawing",
  },
  installComponents: true,
});
